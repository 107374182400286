export const IDENTIFIERS = {
  ACCOUNT: 'accounts',
  ENUMS: 'enums',
  EVOLUTION_STEPS: 'evolution-steps',
  EVOLUTION_PICTURES: 'evolution-pictures',
  PROGRAMS: 'programs',
  PHASES: 'phases',
  DISCOUNTS: 'discounts',
  PAYMENTS: 'payments',
  SPORT_TEST_RESULTS: 'sport-test-results',
  EVALUATIONS: 'evaluations',
}

export const resources = {
  [[IDENTIFIERS.ACCOUNT]]: {
    name: 'Comptes',
    url: 'accounts',
  },
  [[IDENTIFIERS.ENUMS]]: {
    name: IDENTIFIERS.ENUMS,
    url: 'enums',
  },
  [[IDENTIFIERS.EVOLUTION_STEPS]]: {
    name: IDENTIFIERS.EVOLUTION_STEPS,
    url: 'evolution-steps',
  },
  [[IDENTIFIERS.EVOLUTION_PICTURES]]: {
    name: IDENTIFIERS.EVOLUTION_PICTURES,
    url: 'evolution-pictures',
  },
  [[IDENTIFIERS.PROGRAMS]]: {
    name: 'Programmes',
    url: 'programs',
  },
  [[IDENTIFIERS.PAYMENTS]]: {
    name: 'Paiements',
    url: 'payments',
  },
  [[IDENTIFIERS.PHASES]]: {
    name: 'Phases',
    url: 'phases',
  },
  [[IDENTIFIERS.DISCOUNTS]]: {
    name: 'Promotions',
    url: 'discounts',
  },
  [[IDENTIFIERS.SPORT_TEST_RESULTS]]: {
    name: 'Résultats',
    url: 'sport-test-results',
  },
  [[IDENTIFIERS.EVALUATIONS]]: {
    name: 'Évaluations',
    url: 'evaluations',
  },
}

/**
 * Returns the URL according to the resource, or the original parameter if it's not found
 * @param {string} name Resource name
 */
export const getResourceUrl = (name) => {
  if (resources[name]) {
    return resources[name].url
  }
  return name
}

/**
 * Returns the label according to the resource, or the original parameter if it's not found
 * @param {string} name Resource name
 */
export const getResourceLabel = (name) => {
  if (resources[name]) {
    return resources[name].label
  }
  return name
}

export default {
  IDENTIFIERS,
  resources,
  getResourceLabel,
  getResourceUrl,
}
