import React from 'react'
import {
  NumberField, Show, SimpleShowLayout, TextField,
} from 'react-admin'

const PhaseShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <NumberField source="order" label="Numéro de la phase" />
      <TextField source="description" label="Description" />
      <NumberField source="duration" label="Durée en jours" />
      <TextField source="motivation_sentence_male" label="Phrase de motivation pour homme" />
      <TextField source="motivation_sentence_female" label="Phrase de motivation pour femme" />
    </SimpleShowLayout>
  </Show>
)

export default PhaseShow
