import axios from 'axios'

const httpClient = (params = {}) => {
  const options = params
  if (!params.headers) {
    options.headers = { Accept: 'application/json' }
  }
  options.headers.Authorization = `Bearer ${localStorage.getItem('accessToken')}`
  return axios(options).catch((err) => {
    localStorage.setItem('needsRefresh', err.message.includes('401'))
    throw Error('Un erreur est survenue...')
  })
}

export default httpClient
