const dateParser = (v) => {
  // Parse a string date to "YYYY-MM-DD" format
  const match = /(\d{4})-(\d{2})-(\d{2})/.exec(v)
  if (match === null) return
  const d = new Date(match[1], parseInt(match[2], 10) - 1, match[3])
  if (isNaN(d)) return
  return d
}

export default dateParser
