import React from 'react'
import {
  Edit, SimpleForm, NumberField, TextInput,
} from 'react-admin'

const PhaseEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <NumberField source="order" label="Numéro de la phase" />
      <TextInput source="description" label="Description" />
      <NumberField source="duration" label="Durée en jours" />
      <TextInput
        multiline
        source="motivation_sentence_male"
        label="Phrase de motivation pour homme"
      />
      <TextInput
        multiline
        source="motivation_sentence_female"
        label="Phrase de motivation pour femme"
      />
    </SimpleForm>
  </Edit>
)

export default PhaseEdit
