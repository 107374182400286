import React from 'react'
import Chip from '@material-ui/core/Chip'
import { withStyles } from '@material-ui/core/styles'

const styles = {
  main: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: -8,
    marginBottom: -8,
  },
  chip: { margin: 4 },
}

const SimpleChipField = ({ record, classes }) => record ? (
  <span className={classes.main}>
    <Chip key={record} className={classes.chip} label={record} />
  </span>
) : null

SimpleChipField.defaultProps = {
  addLabel: true,
}

export default withStyles(styles)(SimpleChipField)
