import React from 'react'
import {
  List, Datagrid, TextField, CreateButton, NumberField, BooleanField,
} from 'react-admin'

const Actions = ({ basePath }) => <CreateButton basePath={basePath} />

const DiscountList = (props) => (
  <List {...props} actions={<Actions />}>
    <Datagrid rowClick="show">
      <TextField source="id" />
      <TextField source="code" label="Code promo" />
      <NumberField source="discount_rate" label="Taux de réduction (%)" />
      <TextField source="comment" label="Commentaire" />
      <BooleanField source="active" label="Code actif" />
    </Datagrid>
  </List>
)

export default DiscountList
