import React from 'react'
import {
  NumberField,
  Show,
  Tab,
  TabbedShowLayout,
  TextField,
  DateField,
  ArrayField,
  SingleFieldList,
  ImageField,
  ReferenceArrayField,
  ReferenceManyField,
  FunctionField,
  Datagrid,
  ReferenceField,
} from 'react-admin'

import { withStyles } from '@material-ui/core/styles'
import AddPaymentButton from './AddPaymentButton'
import SimpleChipField from '../custom-modules/SimpleChipField'

const imageStyles = {
  image: {
    maxHeight: '30rem',
  },
}

const AccountShow = withStyles(imageStyles)(({ classes, ...props }) => (
  <Show {...props}>
    <TabbedShowLayout scrollable="true">
      <Tab label="Compte">
        <TextField source="status" label="Statut" />
        <TextField source="email" label="Email" />
        <TextField source="authority" label="Autorité" />
      </Tab>
      <Tab label="Profil">
        <TextField source="first_name" label="Prénom" />
        <TextField source="last_name" label="Nom" />
        <DateField source="birth_date" label="Date de naissance" />
        <NumberField source="height" label="Taille" />
        <NumberField source="weight" label="Poids" />
        <NumberField source="weight_goal" label="Poids cible" />
        <NumberField source="fitness_level" label="Niveau de forme" />
        <NumberField source="motivation_level" label="Niveau de motivation" />
        <ArrayField source="goals" label="Objectifs (3)">
          <SingleFieldList>
            <SimpleChipField />
          </SingleFieldList>
        </ArrayField>
        <TextField source="additional_sport" label="Sport additionnel" />
      </Tab>
      <Tab label="Paiement">
        <ReferenceManyField reference="payments" source="id" target="id" addLabel={false}>
          <Datagrid>
            <TextField source="amount" label="Montant" />
            <NumberField source="discount" label="Réduction appliquée" />
            <TextField source="payment_type" label="Type de paiement" />
            <TextField source="payment_status" label="Statut du paiement" />
            <FunctionField
              source="payment_recurrence"
              label="Récurrence du paiement"
              render={(record) => record.payment_recurrence === 'INSTALLMENTS' ? 'En 3 fois' : 'En une fois'
              }
            />
            <ReferenceField
              label="Promotion appliquée"
              source="discount_id"
              reference="discounts"
              target="id"
              allowEmpty
            >
              <TextField source="code" />
            </ReferenceField>
          </Datagrid>
        </ReferenceManyField>
        <AddPaymentButton />
      </Tab>
      <Tab label="Photos">
        <ReferenceManyField reference="evolution-steps" target="id" addLabel={false}>
          <Datagrid>
            <DateField source="upload_date" label="Date de chargement" />
            <TextField source="consent_type" label="Type de consentement" />
            <ReferenceArrayField
              reference="evolution-pictures"
              source="evolution_pictures"
              target="id"
              label="Photos d'évolution"
            >
              <Datagrid>
                <TextField source="filename" label="Nom du fichier" />
                <ImageField classes={classes} source="pictureUrl" label="Aperçu du fichier" />
              </Datagrid>
            </ReferenceArrayField>
          </Datagrid>
        </ReferenceManyField>
      </Tab>
      <Tab label="Mensurations">
        <ReferenceManyField reference="evolution-steps" target="id" addLabel={false}>
          <Datagrid>
            <DateField source="upload_date" label="Date de chargement" />
            <TextField source="consent_type" label="Type de consentement" />
            <NumberField source="body_measurements.weight" label="Poids" />
            <NumberField source="body_measurements.hip" label="Tour de hanche" />
            <NumberField source="body_measurements.waist" label="Tour de taille" />
            <NumberField source="body_measurements.bust" label="Tour de poitrine" />
            <NumberField source="body_measurements.arm" label="Tour de bras" />
            <NumberField source="body_measurements.thigh" label="Tour de cuisse" />
          </Datagrid>
        </ReferenceManyField>
      </Tab>
      <Tab label="Séances de test">
        <ReferenceManyField reference="sport-test-results" target="id" addLabel={false}>
          <Datagrid>
            <DateField source="date" label="Date du test" />
            <NumberField source="feedback" label="Note sur 5" />
            <ArrayField source="results" label="Résultats">
              <Datagrid>
                <TextField source="question" label="Question" />
                <TextField source="result" label="Réponse" />
              </Datagrid>
            </ArrayField>
          </Datagrid>
        </ReferenceManyField>
      </Tab>
      <Tab label="Évaluations">
        <ReferenceManyField reference="evaluations" target="id" addLabel={false}>
          <Datagrid>
            <DateField source="date" label="Date de l'évaluation" />
            <ArrayField source="results" label="Résultats">
              <Datagrid>
                <TextField source="type" label="Type" />
                <TextField source="question" label="Question" />
                <NumberField source="feedback" label="Réponse" />
              </Datagrid>
            </ArrayField>
          </Datagrid>
        </ReferenceManyField>
      </Tab>
    </TabbedShowLayout>
  </Show>
))

export default AccountShow
