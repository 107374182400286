import React from 'react'
import {
  List, Datagrid, TextField, NumberField, BooleanField,
} from 'react-admin'

const ProgramList = (props) => (
  <List {...props}>
    <Datagrid rowClick="show">
      <TextField source="name" label="Nom" />
      <NumberField source="price" label="Prix" />
      <NumberField source="duration" label="Durée" />
      <BooleanField source="status" label="Statut" />
    </Datagrid>
  </List>
)

export default ProgramList
