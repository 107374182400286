import React from 'react'
import { Link } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import { Button } from 'react-admin'
import PaymentIcon from '@material-ui/icons/Payment'

const styles = {
  button: {
    marginTop: '1em',
  },
}

const AddPaymentButton = ({ classes, record }) => (
  <Button
    className={classes.button}
    variant="raised"
    component={Link}
    to={`/payments/create?profileId=${record.id}`}
    label="Créer un paiement manuel"
    title="Créer un paiement manuel"
  >
    <PaymentIcon />
  </Button>
)

export default withStyles(styles)(AddPaymentButton)
