import React from 'react'
import {
  List, Datagrid, TextField, NumberField,
} from 'react-admin'

const PhaseList = (props) => (
  <List {...props}>
    <Datagrid rowClick="show">
      <NumberField source="order" label="Numéro de la phase" />
      <TextField source="description" label="Description" />
      <NumberField source="duration" label="Durée en jours" />
      <TextField source="motivation_sentence_male" label="Phrase de motivation pour homme" />
      <TextField source="motivation_sentence_female" label="Phrase de motivation pour femme" />
    </Datagrid>
  </List>
)

export default PhaseList
