import React from 'react'
import { Create, SimpleForm, DisabledInput } from 'react-admin'
import { parse } from 'query-string'

const PaymentCreate = (props) => {
  const { profileId: profileId_string } = parse(props.location.search)
  const profileId = profileId_string

  return (
    <Create {...props}>
      <SimpleForm defaultValue={{ profile_id: profileId, payment_type: 'MANUAL' }}>
        <DisabledInput source="profile_id" />
        <DisabledInput source="payment_type" />
      </SimpleForm>
    </Create>
  )
}

export default PaymentCreate
