import React from 'react'
import {
  Create, SimpleForm, TextInput, NumberInput, BooleanInput, required,
} from 'react-admin'

const DiscountCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="code" label="Code" validate={[required()]} />
      <TextInput source="comment" label="Commentaire" />
      <NumberInput source="discount_rate" label="Taux de réduction (%)" validate={[required()]} />
      <BooleanInput source="active" label="Code actif" />
    </SimpleForm>
  </Create>
)

export default DiscountCreate
