import React from 'react'
import {
  List, Datagrid, TextField, NumberField, ReferenceField, FunctionField,
} from 'react-admin'

const PaymentList = (props) => (
  <List {...props} hasCreate={false}>
    <Datagrid rowClick="show">
      <TextField source="amount" label="Montant" />
      <NumberField source="discount" label="Réduction appliquée" />
      <TextField source="payment_type" label="Type de paiement" />
      <TextField source="payment_status" label="Statut du paiement" />
      <FunctionField
        source="payment_recurrence"
        label="Récurrence du paiement"
        render={(record) => record.payment_recurrence === 'INSTALLMENTS' ? 'En 3 fois' : 'En une fois'}
      />

      <ReferenceField
        label="Produit acheté"
        source="product_id"
        reference="programs"
        target="id"
        allowEmpty
      >
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField
        label="Email acheteur"
        source="profile_id"
        reference="accounts"
        target="id"
        allowEmpty
      >
        <TextField source="email" />
      </ReferenceField>
      <ReferenceField
        label="Promotion appliquée"
        source="discount_id"
        reference="discounts"
        target="id"
        allowEmpty
      >
        <TextField source="code" />
      </ReferenceField>
    </Datagrid>
  </List>
)

export default PaymentList
