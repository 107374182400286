import React from 'react'
import {
  List, Datagrid, TextField, CreateButton, Filter, TextInput,
} from 'react-admin'

const Actions = ({ basePath }) => <CreateButton basePath={basePath} />

const AccountFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Recherche par email" source="qEmail" alwaysOn />
  </Filter>
)

const AccountList = (props) => (
  <List {...props} actions={<Actions />} filters={<AccountFilter />}>
    <Datagrid rowClick="show">
      <TextField source="email" />
      <TextField source="first_name" label="Prénom" />
      <TextField source="last_name" label="Nom" />
      <TextField source="status" label="Statut" />
      <TextField source="authority" label="Autorité" />
    </Datagrid>
  </List>
)

export default AccountList
