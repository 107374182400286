import React from 'react'
import {
  List, Datagrid, TextField, ReferenceField, DateField,
} from 'react-admin'

const SportTestResultsList = (props) => (
  <List {...props}>
    <Datagrid rowClick="show">
      <ReferenceField label="Email" source="profileId" reference="accounts" target="id">
        <TextField source="email" />
      </ReferenceField>
      <DateField source="date" label="Date du test" />
    </Datagrid>
  </List>
)

export default SportTestResultsList
