// In src/App.js
import React from 'react'
import { Admin, Resource } from 'react-admin'
import dotenv from 'dotenv'
import frenchMessages from 'ra-language-french'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle'
import FitnessCenterIcon from '@material-ui/icons/FitnessCenter'
import ClearAllIcon from '@material-ui/icons/ClearAll'
import LocalOfferIcon from '@material-ui/icons/LocalOffer'
import CreditCardIcon from '@material-ui/icons/CreditCard'
import AssignmentIcon from '@material-ui/icons/Assignment'
import InsertChartIcon from '@material-ui/icons/InsertChart'
import TuneIcon from '@material-ui/icons/Tune'
import { createMuiTheme } from '@material-ui/core/styles'
import HttpProvider from './providers/http/provider'
import authProvider from './providers/http/authProvider'
import { IDENTIFIERS as RESOURCE_IDENTIFIERS } from './config/resources'
import AccountList from './pages/accounts/AccountList'
import AccountCreate from './pages/accounts/AccountCreate'
import AccountEdit from './pages/accounts/AccountEdit'
import AccountShow from './pages/accounts/AccountShow'
import EnumsShow from './pages/enums/EnumsShow'
import ProgramList from './pages/program/ProgramList'
import ProgramShow from './pages/program/ProgramShow'
import { LoginPage } from './pages/login/LoginPage'
import PhaseList from './pages/phases/PhaseList'
import PhaseEdit from './pages/phases/PhaseEdit'
import PhaseShow from './pages/phases/PhaseShow'
import DiscountList from './pages/discounts/DiscountList'
import DiscountCreate from './pages/discounts/DiscountCreate'
import DiscountEdit from './pages/discounts/DiscountEdit'
import DiscountShow from './pages/discounts/DiscountShow'
import PaymentList from './pages/payments/PaymentList'
import PaymentShow from './pages/payments/PaymentShow'
import PaymentCreate from './pages/payments/PaymentCreate'
import SportTestResultsList from './pages/sport-test-results/SportTestResultsList'
import SportTestResultsShow from './pages/sport-test-results/SportTestResultsShow'
import EvolutionStepsList from './pages/evolution-steps/EvolutionStepsList'
import EvolutionStepsShow from './pages/evolution-steps/EvolutionStepsShow'
import EvaluationsList from './pages/evaluations/EvaluationsList'
import EvaluationsShow from './pages/evaluations/EvaluationsShow'

dotenv.config()

const i18nProvider = (locale) => frenchMessages

const theme = createMuiTheme({
  palette: {
    secondary: {
      main: '#000000',
      contrastText: '#FFFFFF',
    },
  },
})

const App = () => (
  <Admin
    theme={theme}
    locale="fr"
    i18nProvider={i18nProvider}
    dataProvider={HttpProvider}
    authProvider={authProvider}
    loginPage={LoginPage}
  >
    {(permissions) => [
      permissions === 'ADMIN' ? (
        <Resource
          name={RESOURCE_IDENTIFIERS.ACCOUNT}
          list={AccountList}
          create={AccountCreate}
          edit={AccountEdit}
          show={AccountShow}
          icon={AccountCircleIcon}
          options={{ label: 'Comptes' }}
        />
      ) : null,
      <Resource
        name={RESOURCE_IDENTIFIERS.PROGRAMS}
        list={ProgramList}
        show={ProgramShow}
        icon={FitnessCenterIcon}
        options={{ label: 'Programmes' }}
      />,
      <Resource
        name={RESOURCE_IDENTIFIERS.ENUMS}
        show={EnumsShow}
        icon={SupervisedUserCircleIcon}
      />,
      <Resource
        name={RESOURCE_IDENTIFIERS.PHASES}
        list={PhaseList}
        edit={PhaseEdit}
        show={PhaseShow}
        icon={ClearAllIcon}
        options={{ label: 'Phases' }}
      />,
      <Resource
        name={RESOURCE_IDENTIFIERS.DISCOUNTS}
        list={DiscountList}
        create={DiscountCreate}
        edit={DiscountEdit}
        show={DiscountShow}
        icon={LocalOfferIcon}
        options={{ label: 'Promotions' }}
      />,
      <Resource
        name={RESOURCE_IDENTIFIERS.PAYMENTS}
        create={PaymentCreate}
        list={PaymentList}
        show={PaymentShow}
        icon={CreditCardIcon}
        options={{ label: 'Paiements' }}
      />,
      <Resource
        name={RESOURCE_IDENTIFIERS.EVOLUTION_STEPS}
        list={EvolutionStepsList}
        show={EvolutionStepsShow}
        icon={InsertChartIcon}
        options={{ label: 'Photos/Mensurations' }}
      />,
      <Resource name={RESOURCE_IDENTIFIERS.EVOLUTION_PICTURES} />,
      <Resource
        name={RESOURCE_IDENTIFIERS.SPORT_TEST_RESULTS}
        list={SportTestResultsList}
        show={SportTestResultsShow}
        icon={AssignmentIcon}
        options={{ label: 'Séances de test' }}
      />,
      <Resource
        name={RESOURCE_IDENTIFIERS.EVALUATIONS}
        list={EvaluationsList}
        show={EvaluationsShow}
        icon={TuneIcon}
        options={{ label: 'Évaluations' }}
      />,
    ]}
  </Admin>
)
export default App
