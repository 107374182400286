import React from 'react'
import {
  Show, SimpleShowLayout, TextField, NumberField, BooleanField,
} from 'react-admin'

const DiscountCreate = (props) => (
  <Show {...props}>
    <SimpleShowLayout redirect="show">
      <TextField source="code" label="Code" />
      <TextField source="comment" label="Commentaire" />
      <NumberField source="discount_rate" label="Taux de réduction (%)" />
      <BooleanField source="active" label="Code actif" />
    </SimpleShowLayout>
  </Show>
)

export default DiscountCreate
