import React from 'react'
import {
  Show,
  SimpleShowLayout,
  Datagrid,
  ImageField,
  TextField,
  DateField,
  ReferenceArrayField,
  ReferenceField,
  NumberField,
} from 'react-admin'
import { withStyles } from '@material-ui/core/styles'

const imageStyles = {
  image: {
    maxHeight: '30rem',
  },
}

const EvolutionStepsShow = withStyles(imageStyles)(({ classes, ...props }) => (
  <Show {...props}>
    <SimpleShowLayout redirect="show">
      <ReferenceField label="Email" source="profile" reference="accounts" target="id">
        <TextField source="email" />
      </ReferenceField>
      <DateField source="upload_date" label="Date de chargement" />
      <TextField source="consent_type" label="Type de consentement" />
      <NumberField source="body_measurements.weight" label="Poids" />
      <NumberField source="body_measurements.hip" label="Tour de hanche" />
      <NumberField source="body_measurements.waist" label="Tour de taille" />
      <NumberField source="body_measurements.bust" label="Tour de poitrine" />
      <NumberField source="body_measurements.arm" label="Tour de bras" />
      <NumberField source="body_measurements.thigh" label="Tour de cuisse" />
      <ReferenceArrayField
        label="Photos d'évolution"
        reference="evolution-pictures"
        source="evolution_pictures"
        target="id"
      >
        <Datagrid>
          <TextField source="filename" label="Nom du fichier" />
          <ImageField classes={classes} source="pictureUrl" label="Aperçu du fichier" />
        </Datagrid>
      </ReferenceArrayField>
    </SimpleShowLayout>
  </Show>
))

export default EvolutionStepsShow
