import React from 'react'
import {
  List, Datagrid, DateField, TextField, ReferenceField, FunctionField,
} from 'react-admin'

const EvolutionStepsList = (props) => (
  <List {...props}>
    <Datagrid rowClick="show">
      <ReferenceField label="Email" source="profile" reference="accounts" target="id">
        <TextField source="email" />
      </ReferenceField>
      <DateField source="upload_date" label="Date de l'évolution" />
      <FunctionField
        source="body_measurements"
        label="Mensurations fournies"
        render={(record) => record.body_measurements.weight !== 0
          || record.body_measurements.hip !== 0
          || record.body_measurements.waist !== 0
          || record.body_measurements.bust !== 0
          || record.body_measurements.arm !== 0
          || record.body_measurements.thigh !== 0
          ? 'Oui'
          : 'Non'
        }
      />
      <TextField source="consent_type" label="Type de consentement" />
    </Datagrid>
  </List>
)

export default EvolutionStepsList
