import React from 'react'
import {
  Create,
  FormTab,
  TabbedForm,
  TextInput,
  ReferenceInput,
  SelectInput,
  ReferenceArrayInput,
  CheckboxGroupInput,
  number,
  minValue,
  maxValue,
  NumberInput,
  DateInput,
} from 'react-admin'
import dateParser from '../utils/date-utils'

const validateFitnessLevel = [number(), minValue(1), maxValue(5)]
const validateMotivationLevel = [number(), minValue(1), maxValue(4)]

const AccountCreate = (props) => (
  <Create {...props}>
    <TabbedForm redirect="show">
      <FormTab label="Détails">
        <TextInput source="email" label="Email" />
        <TextInput source="password" label="Mot de passe" />
        <ReferenceInput
          label="Statut"
          source="status"
          reference="enums"
          key="status"
          filter={{ enum: 'accountStatus' }}
        >
          <SelectInput optionText="name" />
        </ReferenceInput>
        <ReferenceInput
          label="Autorité"
          source="authority"
          reference="enums"
          key="authority"
          filter={{ enum: 'authority' }}
        >
          <SelectInput optionText="name" />
        </ReferenceInput>
        <TextInput source="first_name" label="Prénom" />
        <TextInput source="last_name" label="Nom" />
        <DateInput source="birth_date" label="Date de naissance" parse={dateParser} />
        <NumberInput source="weight" label="Poids" />
        <NumberInput source="weight_goal" label="Poids cible" />
        <NumberInput
          source="fitness_level"
          label="Forme physique (Note de 1 à 5)"
          validate={validateFitnessLevel}
        />
        <NumberInput
          source="motivation_level"
          label="Motivation (Note de 1 à 4)"
          validate={validateMotivationLevel}
        />
        <ReferenceArrayInput
          label="Objectifs (3)"
          source="goals"
          reference="enums"
          key="goals"
          filter={{ enum: 'goals' }}
        >
          <CheckboxGroupInput
            optionValue="id"
            optionText="name"
            allowEmpty
            onChange={(value) => value}
          />
        </ReferenceArrayInput>
        <ReferenceInput
          label="Sport additionnel"
          source="additional_sport"
          reference="enums"
          key="sports"
          filter={{ enum: 'sports' }}
        >
          <SelectInput optionText="name" />
        </ReferenceInput>
      </FormTab>
    </TabbedForm>
  </Create>
)

export default AccountCreate
