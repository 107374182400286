import React from 'react'
import {
  Show, TextField, NumberField, BooleanField, SimpleShowLayout,
} from 'react-admin'

const ProgramShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="name" label="Nom" />
      <NumberField source="price" label="Prix" />
      <NumberField source="duration" label="Durée" />
      <BooleanField source="status" label="Statut" />
    </SimpleShowLayout>
  </Show>
)

export default ProgramShow
