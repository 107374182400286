import React from 'react'
import {
  NumberField,
  Show,
  TextField,
  SimpleShowLayout,
  ReferenceField,
  FunctionField,
} from 'react-admin'

const PaymentShow = (props) => (
  <Show {...props} hasCreate={false}>
    <SimpleShowLayout>
      <TextField source="amount" label="Montant" />
      <NumberField source="discount" label="Réduction appliquée" />
      <TextField source="payment_type" label="Type de paiement" />
      <TextField source="payment_status" label="Statut du paiement" />
      <FunctionField
        source="payment_recurrence"
        label="Récurrence du paiement"
        render={(record) => record.payment_recurrence === 'INSTALLMENTS' ? 'En 3 fois' : 'En une fois'}
      />

      <ReferenceField label="Produit acheté" source="product_id" reference="programs" target="id">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField label="Email acheteur" source="profile_id" reference="accounts" target="id">
        <TextField source="email" />
      </ReferenceField>
    </SimpleShowLayout>
  </Show>
)

export default PaymentShow
