import React from 'react'
import {
  Show,
  TextField,
  SimpleShowLayout,
  Datagrid,
  ArrayField,
  ReferenceField,
  DateField,
  NumberField,
} from 'react-admin'

const SportTestResultsShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout redirect="show">
      <ReferenceField label="Email" source="profileId" reference="accounts" target="id">
        <TextField source="email" />
      </ReferenceField>
      <DateField source="date" label="Date du test" />
      <NumberField source="feedback" label="Note sur 5" />
      <ArrayField source="results" label="Résultats">
        <Datagrid>
          <TextField source="question" label="Question" />
          <TextField source="result" label="Réponse" />
        </Datagrid>
      </ArrayField>
    </SimpleShowLayout>
  </Show>
)

export default SportTestResultsShow
